<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 商城订单
 * @Date: 2020-12-28 22:19:55
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-24 14:33:19
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/Shopping.vue
-->
<template>
  <div class="user_content">
    <div v-show="$route.query.state!==5">
      <van-tabs v-if="type !== 5" v-model="type" @click="tabkey">
        <van-tab v-for="(item,index) in tablist" :key="index" :title="item.name" />
      </van-tabs>
    </div>
    <skeleton v-show="loading" type="order" />
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList">
        <div v-if="!classList.length && !loading" class="ordernull">
          <van-empty
            image="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
            description="暂无数据"
          />
        </div>
        <div v-else class="orderlist">
          <ul>
            <li v-for="(item,index) in classList" :key="index">
              <div class="sptitle">
                <div><img :src="item.splImg" alt=""><span>{{ item.splName }}</span></div>
                <div>{{ item.orderTypeValue }}</div>
              </div>
              <div class="spcenter" @click="goOrderDetails(item)">
                <div><img :src="item.goodCover[0]" alt=""></div>
                <div class="spcenter_text">
                  <div>{{ item.goodName }}</div>
                  <div>
                    <p>¥{{ item.payPrice }}</p>
                    <p>*{{ item.goodNum }}</p>
                  </div>
                </div>
              </div>
              <div class="spfoot">
                <span
                  v-if="item.orderState === 2 || item.orderState === 5 || item.orderState === 4"
                  class="s st"
                  @click="logistics(item)"
                >查看物流</span>
                <span v-if="item.isMakesure === 1" class="s sc" @click="goOrderDetails(item)">确认收货</span>
                <span v-if="item.isCanComment === 1" class="s sc" @click="evaluate(item)">去评价</span>
                <span v-if="item.orderState === 3" class="s st" @click="Delete(item)">删除订单</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </mescroll>
  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog, Empty, Tab, Tabs, Toast } from 'vant'

Vue.use(Tab).use(Tabs).use(Empty).use(Toast)
import mescroll from '@/component/fresh_scroll/mescroll'
import skeleton from '@/component/skeleton/index'
import {
  classList,
  mallNewOrderDelete
} from '@/services/userApi'

export default {
  components: {
    mescroll,
    skeleton
  },
  data() {
    return {
      status: '',
      loading: true,
      mescrollDown: {
        use: false,
        bottom: '0',
        top: '40px'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true
        }
      },
      type: 0,
      page: 1,
      pageSize: 10,
      classList: [],
      tablist: [
        { name: '全部' },
        { name: '待付款' },
        { name: '待发货' },
        { name: '待收货' },
        { name: '待评价' },
        { name: '退货售后' }
      ]
    }
  },
  created() {
    this.type = this.$route.query.state * 1
    if (this.type !== 5) {
      this.tablist = [
        { name: '全部' },
        { name: '待付款' },
        { name: '待发货' },
        { name: '待收货' },
        { name: '待评价' }
      ]
    }
    this.tabkey(this.type)
  },
  methods: {
    upCallback(page, mescroll) {
      this.getinfo(page.num, page.size, (arr) => {
        if (page.num === 1) this.classList = []
        this.classList = this.classList.concat(arr)
        this.$nextTick(() => {
          mescroll.endSuccess(arr.length)
        })
      }, () => {
        mescroll.endErr()
      })
    },
    getinfo(pageNum, pageSize, successCallback, errorCallback) {
      const prom = {
        orderType: this.status,
        page: this.page,
        pageSize: this.pageSize
      }
      if (this.$route.query.state === 5) {
        prom.orderType = 5
      }
      classList(prom).then(res => {
        this.loading = true
        if (Number(res.code) === 200 && res.list.length > 0) {
          successCallback(res.list)
          this.page++
          this.loading = false
        } else {
          errorCallback(res.msg)
          this.loading = false
        }
        this.loading = false
      })
    },
    tabkey(t, y) {
      let status = null
      switch (t) {
        case 0:
          status = 0
          break
        case 1:
          status = 1
          break
        case 2:
          status = 7
          break
        case 3:
          status = 2
          break
        case 4:
          status = 6
          break
        case 5:
          status = 5
          break
      }
      this.status = status
      this.loading = true
      this.page = 1
      this.classList = []
      this.mescroll && this.mescroll.resetUpScroll && this.mescroll.resetUpScroll()
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    goOrderDetails(v) {
      this.$router.push({ path: '/Orderdetails', query: { orderNo: v.orderSon }})
    },
    // 查看物流
    logistics(v) {
      this.$router.push({
        path: '/logistics',
        query: {
          recordId: v.recordId,
          goodName: v.goodName,
          goodSpec: v.goodSpec,
          payPrice: v.payPrice,
          goodCover: v.goodCover,
          goodNum: v.goodNum
        }
      })
    },
    // 去评价
    evaluate(n) {
      this.$router.push({
        path: '/evaluate',
        query: {
          info: n
        }
      })
    },
    // 删除订单
    Delete(n) {
      Dialog.confirm({
        message: '确认删除此订单'
      }).then(() => {
        const prom = {
          orderNo: n.orderNo
        }
        mallNewOrderDelete(prom).then(res => {
          if (Number(res.code) === 200) {
            Toast(res.msg)
            // this.mescrollInit()

            const prom = {
              orderType: this.status,
              page: this.page,
              pageSize: this.pageSize
            }
            classList(prom).then(res => {
              this.classList = res.list
            })
          } else {
            Toast(res.msg)
          }
        })
      }).catch(() => {
        // on cancel
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .ordernull {
    padding: 13px;
  }

  .orderlist {
    & ul li {
      background: #FFFFFF;
      margin: 13px;
      padding: 10px;

      .sptitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div:nth-child(1) {
          display: flex;
          align-items: center;
          color: #333333;
          font-size: 15px;
          font-weight: 500;

          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }

        & > div:nth-child(2) {
          //color: #E33F44;
          color: #666;
          font-size: 12px;
        }
      }

      .spcenter {
        margin: 10px 0 0;
        display: flex;

        & > div:nth-child(1) {
          img {
            max-width: 74px;
            max-height: 74px;
            border-radius: 3px;
          }
        }

        .spcenter_text {
          width: 100%;
          margin: 5px 10px;
          display: flex;
          justify-content: space-between;

          & > div:nth-child(1) {
            flex: 10;
            color: #333333;
            text-align: left;
          }

          & > div:nth-child(2) {
            flex: 2;
            text-align: right;
            padding-left: 5px;

            & > p:nth-child(1) {
              color: #333333;
            }

            & > p:nth-child(2) {
              color: #999999;
            }
          }
        }
      }

      .spfoot {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .s {
          border-radius: 15px;
          padding: 5px 10px;
          margin-right: 10px;
        }

        .st {
          color: #999999;
          border: 1px solid #999999;
        }

        .sc {
          color: #E43F44;
          border: 1px solid #E43F44;
        }
      }
    }
  }
}
</style>
